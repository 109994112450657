import React, {FC} from 'react';
import classNames from 'classnames';

export interface CheckboxProps {
  active: boolean;
  onChange?: (active: boolean) => void;
  disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({active, onChange, disabled}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange?.(event.target.checked);
  };

  return (
    <label className="ge-checkbox-wrapper">
      <input
        type="checkbox"
        checked={active}
        disabled={disabled}
        onChange={handleInputChange}
        className="ge-checkbox-input"
        data-testid="ge-checkbox-input"
        aria-checked={active}
      />
      <span className={classNames('ge-checkbox', {active})} data-testid="ge-checkbox">
        {active ? <i data-testid="ge-checkbox__icon" className="icon icon-checkmark" /> : null}
      </span>
    </label>
  );
};

export default Checkbox;
