export enum FeatureFlags {
  OrderReminderNotification = '2021-q4-order-reminder-notification',
  AbandonedCartNotification = '2021-q4-abandoned-cart-notification',
  ShowGiftCardOnReferralPage = '2022-q1-show-gift-card-referrals',
  ProductsFromProducer = '2022-q1-products-from-producer-pdp',
  RemovePhoneInput = '2022-q2-signup-form-remove-phone-number-requirement',
  AppDownloadUpsellOnOrderConfirmation = '2022-q2-app-download-upsell-on-order-confirmation',
  TippingWeb = '2024-q2-tipping-web',
  HidePreorderDaySelector = '2024-q4-hide-preorder-day-selector',
}
